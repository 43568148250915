<template>
  <div>
    <div class="mb-5">
      <b-modal id="add-update-department-modal" title="Department Add/Update" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">

          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="department_name" class="col-form-label col-form-label-sm">Department Name <span class="custom-required">*</span></label>
              <input type="text" v-model="department.department_name" v-validate="'required|max:255'" id="department_name" name="department_name" class="form-control form-control-sm" placeholder="Enter department name"/>
              <div class="invalid-feedback">Department name is required</div>
            </div>
          </div>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" v-if="!this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="save">Add</a-button>
              <a-button type="primary" v-if="this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('add-update-department-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'addUpdateDepartment',
  props: ['department', 'btnEdit'],
  data() {
    return {
      validation_errors: {},
      show: false,
      loader: false,
    }
  },
  mounted() { },
  methods: {
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/departments', this.department).then(response => {
            this.loader = false
            if (response.data.error) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#department_list').DataTable().destroy()
              this.$emit('resetForm')
              this.$emit('getDepartments')
            }
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.put('api/departments/' + this.department.id, this.department)
            .then(response => {
              if (!response.data.error) {
                this.loader = false
                $('#department_list').DataTable().destroy()
                this.$bvModal.hide('add-update-department-modal')
                this.$emit('getDepartments')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.loader = false
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
